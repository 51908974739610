import reviewImage from '../Images/customer.jpeg'

export const FakeBlog = [
    {
        "id": "001",
        "title": "It has been a tremendous experience with you. During the lockdown you helped us for the healing. He is Always available on call also. Thank you Doctor for your time and treatment",
        "span1": "Posted by: Siddhesh Desai",
        "span2": "12 Oct, 2019",
        "img": reviewImage
    },
    {
        "id": "002",
        "title": "Dr Arlekar is one of the best doctors I've ever had. He has been our family doctor for the past 25 years. Immediately when he enters the room, he takes time to get to know you and is so patient and is such a good listener.",
        "span1": "Posted by: Abhijit Khedekar",
        "span2": "14 Oct, 2019",
        "img": reviewImage
    },
    {
        "id": "003",
        "title": "Thank you for being available every time whenever we needed you. You all have been such kind and caring doctors for me.",
        "span1": "Posted by: Chandrika Pawaskar",
        "span2": "10 Jan, 2020",
        "img": reviewImage
    },
    {
        "id": "004",
            "span1": "Posted by: Nagendra Zha",
        "span2": "10 Jan, 2022",
        "img": reviewImage,
       "title": "We have been consulting with Dr. Arlekar for 10 years and it has been a really amazing experience.  Whenever we fall sick , we get ourselves checked from Dr. Arlekar. We sincerely appreciate the care and compassion  shown by him .  And we are truly grateful for the difference  he has made in our lives.- Mr Nagendra Zha"
    }
]