export const FakeFeatures = [
    {
        "id": "001",
        "title": "Complete Treatment",
        "description": "We provide a wholesome treatment using Ayurvedic and Allopathic techniques.",
        "img": "https://raw.githubusercontent.com/saifulemon/images-for-all-project/main/Donto/Features/feature3.svg"
    },
  
    {
        "id": "002",
        "title": "Panchakarma",
        "description": "Kati basti, janu basti, manya basti, nasya, Pottali swed and  Jalaukavacharan.",
        "img": "https://raw.githubusercontent.com/saifulemon/images-for-all-project/main/Donto/Features/feature3.svg"
    },
    {
        "id": "003",
        "title": "Lifestyle management",
        "description": "We improve your lifestyle by helping you make the right choices" ,
        "img": "https://raw.githubusercontent.com/saifulemon/images-for-all-project/main/Donto/Features/feature3.svg"
    },
    {
        "id": "004",
        "title": "Ayurvedic medicines",
        "description": "Our expertise lies in using Ayurveda to cure your illnesses.",
        "img": "https://raw.githubusercontent.com/saifulemon/images-for-all-project/main/Donto/Features/feature3.svg"
    },
    {
        "id": "005",
        "title": "Suvarna Prashan",
        "description": "Give your children a better immunity, memory and concentration",
        "img": "https://raw.githubusercontent.com/saifulemon/images-for-all-project/main/Donto/Features/feature3.svg"
    },   
    {
        "id": "006",
        "title": "Diet consultation",
        "description": "We help you in making the right food choices based on your lifestyle",
        "img": "https://raw.githubusercontent.com/saifulemon/images-for-all-project/main/Donto/Features/feature3.svg"
    }
]


