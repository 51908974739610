export const AboutFocus = [
    {
        "id": "001",
        "title": "Analyze",
        "description": "Our approach is to analyze each patient’s history, diet, lifestyle, and prakruti to get to the root cause of his ailment",
        "img": "https://i.ibb.co/GQmKHnh/heart.png"
    },
    {
        "id": "002",
        "title": "Diagnose",
        "description": "We diagnose the ailment and formulate a personalised treatment plan  and eradicate the disease completely."
,
        "img": "https://i.ibb.co/N2M8n97/love.png"
    },
    {
        "id": "003",
        "title": "Treat",
        "description": "We strive for excellence with an integrated approach of Ayurveda and modern diagnostic techniques",
        "img": "https://i.ibb.co/t2cczRh/outcome.png"
    }
]