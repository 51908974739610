import {  HashRouter, Route, Routes} from 'react-router-dom';
import './App.css';

import About from './Pages/About/About/About';

import Contact from './Pages/Contact/Contact/Contact';
import Dentist from './Pages/Dentist/Denitst/DoctorPage';
import Footer from './Pages/Home/Footer/Footer.jsx';
import Header from './Pages/Home/Header/Header.jsx';
import Home from './Pages/Home/Home/Home.jsx';

import Appointment from './Pages/Home/Appoinment/Appoinment';
import NotFound from './Pages/NotFound/NotFound';
import Service from './Pages/Services/Service/Service';
import DoctorPage from './Pages/Dentist/Denitst/DoctorPage';
import LatestBlog from './components/LatestBlog/LatestBlog';
function App() {
 
  return (
    <div className="App">
     
      <HashRouter>

        <Header />
      
     
  
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/home' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/service' element={<Service />} />
          <Route path='/doctor' element={<DoctorPage />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/Appointment' element={<Appointment/>} />
          <Route path='/Blog' element={  <LatestBlog />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
        <Footer />
      </HashRouter>
   
    </div>
  );
}

export default App;
