import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';


import './Header.css';
import logo from '../../../Images/logo.jpeg'
import { useEffect } from 'react';
const Header = () => {
    const googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement(
          {
            pageLanguage: "en",
            autoDisplay: false,
            includedLanguages:'hi,en,mr'
          },
          "google_translate_element"
        );
      };
      useEffect(() => {
        var addScript = document.createElement("script");
        addScript.setAttribute(
          "src",
          "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
        );
        document.body.appendChild(addScript);
        window.googleTranslateElementInit = googleTranslateElementInit;
      }, []);
    

    return (
     
        <div className="head-bg">
         
            <Navbar className="navbar" collapseOnSelect expand="lg">
                <Container className="container-head">
                    <Navbar.Brand href="/home" style={{fontSize:"17px"}} ><img src={logo} height ="50px" width="50px" alt="logo" /> Dr. Arlekar's Wellness Clinic</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" expand="lg"/>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto align-items-center">
                            <Link to="/home" className='list-item text-decoration-none'>Home</Link>
                            <Link to="/about" className='list-item text-decoration-none'>Information</Link>
                            <Link to="/service" className='list-item text-decoration-none'>Services</Link>
                            <Link to="/doctor" className='list-item text-decoration-none'>Doctors</Link>
                             <Link to="/blog" className='list-item text-decoration-none'>Reviews</Link> 
                            <Link to="/contact" className='list-item text-decoration-none'>Contact</Link>
                        
                            <span  id="google_translate_element"></span>
                        </Nav>
                       
                    </Navbar.Collapse>
                   
                </Container>
               
            </Navbar>
          
        </div>
    );
};

export default Header;