import { faEnvelope, faHome, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './ContactUs.css';

const ContactUs = () => {
    return (
        <section className="contactUs-wrapper">
            <Container>
                <Row>
                    <Col md={6} lg={6} xl={4}>
                    <a target="_blank" href="https://goo.gl/maps/WFDDenEK1UXJoZUH8">
                        <div className="single-contact icon1">
                       
                            <div className="c-icon">
                                
                                <FontAwesomeIcon icon={faHome} >
                            
                                    </FontAwesomeIcon>
                            </div>
                           
                            <div className="c-info text-start">
                               
                           
                             
                                <p>D-7 Plot No. 22</p>
                                <p>Gorai 1, Shree Ashirwad CHS</p>
                                <p>Borivali West, Mumabai.</p>
                             
                              


                            </div>
                           
                        </div>
                        </a>
                    </Col>
                    <Col md={6} lg={6} xl={4}>
                    <a target="_blank" href = "mailto:drarlekarswellnessclinic@gmail.com">
                        <div className="single-contact icon2">
                            <div className="c-icon">
                             
                                <FontAwesomeIcon icon={faEnvelope} />
                            </div>

                            <div className="c-info text-start">
                               
                             
                                <p>drarlekarswellness</p>
                                <p>clinic@gmail.com</p>
                            </div>
                            
                        </div>
                        </a>
                    </Col>
                    <Col md={6} lg={6} xl={4}>
                    <a  target="_blank" href="https://api.whatsapp.com/send?phone=919870092058">
                        <div className="single-contact icon3">
                            <div className="c-icon">
                                <FontAwesomeIcon icon={faPhone} />
                            </div>
                            <div className="c-info text-start">
                           
                                <p>9870092058</p>
                                <p>9870192059 </p>
                             
                            </div>
                        </div>
                        </a>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default ContactUs;