import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import './Dentist.css';

const Dentist = () => {
    return (
        <section className='expert-dentist'>
            <Container>
                <Row className="align-items-center">
              


                    <Col lg={6}>
                        <div className="expertDentist-txt mt-5 mt-lg-0">
                            <h2>
                                
                            </h2>
                           <p>
 
                           नमस्कार मंडळी,
सध्या मे महिना सुरू आहे आणि बाहेर तीव्र उन्हाच्या झळा आहेत.थोडंसं काम केलं तरी घामाच्या धारा लागताहेत आणि थकवा येतोय.या तीव्र उष्म्याचा त्रास होऊ नये म्हणून काय करता येईल पाहुयात.
शक्यतो सकाळी 11ते 3 या वेळेत कारणाशिवाय बाहेर जाणे टाळावे. जाणे जरूरी असल्यास टोपी,स्कार्फ,छत्री तसंच डोळ्यांसाठी गाॅगल वापरावा. हलके ,सुती कपडे वापरावे.बाहेर पडताना पाण्याची/सरबताची लहान बाटली जवळ ठेवावी.
कधीकधी कडक उन्हात जास्त वेळ घालवल्यास उष्माघाताचा त्रास होतो.चक्कर येणे,थकवा वाटणे, डोकेदुखी,ताप येणे अशी लक्षणे दिसतात.अशा वेळी तात्काळ अशा व्यक्तीला सावलीत,वातानुकूलित खोलीत हलवावे.खोलीत पंखा/कूलर लावावा.त्याच्या कपाळावर थंड पाण्याच्या पट्ट्या ठेवाव्यात आणि वैद्यकीय मदत घ्यावी.
कधीकधी अति उन्हाळ्याने नाकाचा घोळणा फुटतो म्हणजे नाकातून रक्त येऊ लागते.
अशावेळी नाकावर बाहेरून बर्फाचा शेक द्यावा.रक्तस्राव न थांबल्यास डाॅक्टरांचा सल्ला घ्यावा.
उन्हाळ्यात अजून एक समस्या घाम आणि घामाची दुर्गंधी ही होय.त्यासाठी 2 वेळा आंघोळ करावी.आंघोळीच्या पाण्यात वाळा,चंदन चूर्ण घालावे.दुर्गंधी कमी करण्यासाठी आंघोळीच्या वेळी जिथे जास्त घाम येतो तिथे तुरटीचा खडा फिरवावा.याने अतिघाम-दुर्गंधी कमी होते.
काही जणांचं अंग उन्हाळ्यात घामोळ्याने फुलून जाते.त्यांनी कडुलिंबाच्या पानांच्या पाण्याने आंघोळ करावी.
उन्हात फिरल्याने बरेचदा त्वचा रापते, काळवंडते.त्यासाठी घरी टोमॅटोचा रस,पपईचा गर आणि  आयुर्वेदिक उटणं लावणे उपयुक्त होते.
कधीकधी पायाच्या टाचांना आणि तळपावलांना रुक्षतेने आणि उष्णतेने भेगा पडतात.अशा वेळी रात्री झोपताना खोबरेल तेल,साजुक तूप आणि आयुर्वेदिक मलमे लावल्यास या भेगा भरून येतात.
आता आपण आहाराचा विचार करूयात..
सकाळचा नाश्ता जरूर करावा.त्यात इडली,घावन,डोसा,आंबोळ्या,सोबत नारळाची चटणी,मऊ भात,भाताची पेज,ताक,नाचणी सत्व,एखादं फळ असावं.
दुपारचं जेवणही साधं घ्यावं.
पुऱ्या,वडे,भजी असे तळलेले पदार्थ टाळावेत.भाज्यांमधे कांद्याची पात,पडवळ,कोबी,पालक,दुधी,भेंडी,
सुरण,कोहाळा,फणस अशा भाज्या असाव्यात.तसंच मूग,मसूर यांच्या उसळी करू शकतो.भाज्या-उसळी कमी तिखट,कमी मसालेदार पण रसदार ठेवाव्यात.
सोबत काकडी,टोमॅटो,बीट,कांदा यांची कोशिंबीर,दह्यातली रायती जरूर खावीत.
टोमॅटो सार,सोलकढी,ताकाची कढी यांनी पण रूचीपालट होतो. कैरी,कोथिंबीर,पुदीना यांची चटणी,कवठाची चटणी पण तोंडाला चव आणतात .
या काळात सुके मासे,कोलंबी,लसूण कुळीथ,तिळाचेपदार्थ,कार्ले,मेथी शक्यतो खाऊ नयेत.
रात्रीचं जेवण दुपारसारखं हलकं,कमी मसालेदार करावं.
रात्री झोपताना किंचित साखर घातलेलं दूध घ्यावं.   
गोड पदार्थात आमरस,शेवया/रवा/तांदुळ/दुधी/लाल भोपळा/मखाणे यांच्या खिरी,दुधीहलवा,श्रीखंड,
वेगवेगळे मोरांबे खावेत.पनीर आणि खव्याच्या मिठाया टाळाव्यात. 
उन्हाळ्यात अतिघामाने थकवा येतो,निरुत्साही वाटतं.अशा वेळी लगेच ताजंतवानं होण्यासाठी विविध सरबतं आपल्या दिमतीला ठेवावीत.लिंबू, कोकम,वाळा,गुलाब,बडीशेप,कवठ
द्राक्षं,बेलफळ यांची सरबतं,कैरीचं पन्हं,शहाळ्याचं पाणी,नीरा,
लस्सी,ताक,मठ्ठा,पीयुष असे खूप पर्याय आहेत.उसाचा रसही खूप तरतरी देतो फक्त डायबेटीसच्या रूग्णांनी पिऊ नये.   
निसर्ग आपल्याला या ऋतूत खूप रसाळ फळं देतो .फळांचा राजा आंबा,फणस,जांभळे,करवंदं,
कलिंगड,टरबूज,खरबूज,पांढरे जाम द्राक्षं,मलबेरी,केळी,पेरू,चिकू,ताडगोळे
 या सर्वांवर ताव मारला की तहान-भूकही भागते आणि लगेच उर्जाही मिळते. 
उन्हाळ्याबद्दल बोलायचं आणि आईस्क्रीमला विसरायचं असं तर होऊच शकत नाही.तेव्हा वरच्या साऱ्या गोष्टी सांभाळून  उन्हाळ्यात मधेमधे आईस्क्रीम पार्टी करायला काहीच हरकत नाही ;फक्त तुम्ही खाताय ते आईस्क्रीमच आहे ..फ्रोझन डेझर्ट तर नाही ना याची मात्र खात्री करायला विसरू नका..
डाॅ.अपर्णा प्रविण आर्लेकर 
डाॅ आर्लेकर वेलनेस क्लिनिक
बोरीवली. 
9870192059.
 </p>
 </div>
                 </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Dentist;