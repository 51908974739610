import React from 'react';
import { Col, Container, NavLink, Row } from 'react-bootstrap';
import './Footer.css'

const Footer = () => {
    return (
        <div className="footer-bg">
            <Container>
                <Row className="text-white">
                    <Col xs={6} md={3}>
                        <div className="single-footer-widget">
                            <div className="widget-title">
                                <h2>Practices</h2>
                            </div>
                            <div className="widget-content">
                                <NavLink className="footer-link">For Patients</NavLink>
                                <NavLink className="footer-link">FAQ's</NavLink>
                                <NavLink className="footer-link">About</NavLink>
                                <NavLink className="footer-link">Contact Us</NavLink>
                                <NavLink className="footer-link">Blog</NavLink>
                            </div>
                        </div>
                    </Col>
                    <Col xs={6} md={3}>
                        <div className="single-footer-widget">
                            <div className="widget-title">
                                <h2>Doctors</h2>
                            </div>
                            <div className="widget-content">
                                <NavLink className="footer-link">Dr. Pravin Arlekar</NavLink>
                                <NavLink className="footer-link">Dr. Aparna Arlekar</NavLink>
                                <NavLink className="footer-link">Dr. Ishani Arlekar</NavLink>
                                
                            </div>
                        </div>
                    </Col>
                    <Col xs={6} md={3}>
                        <div className="single-footer-widget">
                            <div className="widget-title">
                                <h2>Timings</h2>
                            </div>
                            <div className="widget-content">
                            <NavLink className="footer-link">Weekdays</NavLink>
                                <NavLink className="footer-link">Morning - 10:30am to 1:30pm</NavLink>
                                <NavLink className="footer-link">Evening - 6:30pm to 10:30pm</NavLink>
                                <NavLink className="footer-link">Sunday closed</NavLink>
                            
                            </div>
                        </div>
                    </Col>
                    <Col xs={6} md={3}>
                        <div className="single-footer-widget">
                            <div className="widget-title">
                                <h2>Address</h2>
                            </div>
                            <div className="widget-content">
                                <NavLink className="footer-link">D-7 Plot No. 22</NavLink>
                                <NavLink className="footer-link">Gorai 1, Shree Ashirwad CHS Ltd.</NavLink>
                                <NavLink className="footer-link">Borivali West, Mumabai.</NavLink>
                                <NavLink className="footer-link">Phone Primary: 9870092058  </NavLink>
                                <NavLink className="footer-link">Phone Secondary: 9870192059  </NavLink> 
                                <NavLink className="footer-link" href = "mailto:drarlekarswellnessclinic@gmail.com">Email: drarlekarswellnessclinic</NavLink>
                                <div><a  style = {{color:'white'}}target="_blank" href="https://goo.gl/maps/WFDDenEK1UXJoZUH8">🌐 Location</a></div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className="footer-copy-right text-center text-white">
                <p className='mb-0'>&copy; 2023 - <span >Dr. Arlekar's Clinic</span> | All Rights Reserved</p>
            </div>
        </div>
    );
};

export default Footer;