import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import logo from '../../../Images/logo.jpeg';
import './About.css';

const About = () => {

    useEffect(() => {
        AOS.init({
                duration: 2000,
            });
        AOS.refresh();
      }, []);
    return (
        <section className="about-wrapper">
            <Container>
                <Row>
                    <Col md={12} lg={6}>
                        <div className="about-left">
                       <img src={logo}></img>
                        </div>
                    </Col>
                    <Col md={12} lg={6}>
                        <div className="about-right mt-5 mt-lg-0">
                            <div className="about-content text-start" data-aos="zoom-in">
                                <h1>Why Choose us?</h1>
                                <p>

                                Dr Arlekar’s wellness clinic is the place  for complete  medical solutions for all types of your health problems.
Dr Pravin Arlekar and Dr Aparna Arlekar have been in your service as General Physicians for all diseases  and Ayurveda specialists for more than 30 years , successfully treating thousands of patients in the most efficient and authentic manner. Dr Ishani Arlekar has brought in a fresh modern approach to our clinic keeping up with new advances in ayurvedic research and  treatments.
Our approach is to analyze each patient’s history, diet, lifestyle, and prakruti to get to the root cause of his ailment , formulate a personalised treatment plan  and eradicate the disease completely. 
We strive for excellence with an integrated approach of our ancient wisdom of Ayurveda as well as modern medicine and diagnostic techniques . Our results speak for themselves , here is why you should choose us.





                                </p>
                                <a href='/page/about'>Read More</a>
                            </div>
                            <div className="fun-fact-sec" data-aos="fade-right">
                                <div className="single-fun">
                                    <span>10K</span>
                                    <span>+</span>
                                    <p>Happy Patients</p>
                                </div>
                                <div className="single-fun sp-fun" data-aos="fade-right">
                                    <span>3</span>
                                    <span></span>
                                    <p>Qualified Doctors</p>
                                </div>
                                {/* <div className="single-fun" data-aos="fade-left">
                                    <span>30</span>
                                    <span></span>
                                    <p>Years Experience</p>
                                </div>
                                <div className="single-fun sp-fun" data-aos="fade-left">
                                    <span>50</span>
                                    <span></span>
                                    <p>medical Awards</p>
                                </div> */}
                                <span className="line"></span>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default About;