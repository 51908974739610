export const FakeService = [
    {
        "id": "001",
        "title": "Diet Consultation",
        "description": "We provide a personalized diet chart based on your habits and lifestyle",
        "link": "Read More",
        "img": "https://raw.githubusercontent.com/saifulemon/images-for-all-project/main/Donto/Features/feature3.svg"
    },
    {
        "id": "002",
        "title": "Lifestyle Management",
        "description": "We Manage your lifestyle by helping you in making the right choices",
        "link": "Read More",
        "img": "https://raw.githubusercontent.com/saifulemon/images-for-all-project/main/Donto/Features/feature3.svg"
    },
    {
        "id": "003",
        "title": "Joint disorders",
        "description": "We treat all kinds of joint pain, disorders and muco-skeletal disorders",
        "link": "Read More",
        "img": "https://raw.githubusercontent.com/saifulemon/images-for-all-project/main/Donto/Features/feature3.svg"
    },
    {
        "id": "004",
        "title": "Skin and Hair problems",
        "description": "We provide our expertise to give you strong and silky hair and a glowing skin",
        "link": "Read More",
        "img": "https://raw.githubusercontent.com/saifulemon/images-for-all-project/main/Donto/Features/feature3.svg"
    },
    {
        "id": "005",
        "title": "Gynecology disorders",
        "description": "We guide you through your journey of womanhood and problems",
        "link": "Read More",
        "img": "https://raw.githubusercontent.com/saifulemon/images-for-all-project/main/Donto/Features/feature3.svg"
    },
    {
        "id": "006",
        "title": "Pregnancy ANC",
        "description": "We make sure that we are there for you throughout those 9 critical months.",
        "link": "Read More",
        "img": "https://raw.githubusercontent.com/saifulemon/images-for-all-project/main/Donto/Features/feature3.svg"
    },
    {
        "id": "007",
        "title": "Infertility",
        "description": "We treat all infertility related issues and help you in your journey to achieve parenthood",
        "link": "Read More",
        "img": "https://raw.githubusercontent.com/saifulemon/images-for-all-project/main/Donto/Features/feature3.svg"
    },
    {
        "id": "008",
        "title": "Kidney, Gall bladder Stones",
        "description": "We help you get rid of stones in your kindneys and gall bladder",
        "link": "Read More",
        "img": "https://raw.githubusercontent.com/saifulemon/images-for-all-project/main/Donto/Features/feature3.svg"
    } ,
     {
        "id": "009",
        "title": "Piles, Fissure , Fistula",
        "description": "We help you fight, All the problems pertaining to the large intestine and digestive tract",
        "link": "Read More",
        "img": "https://raw.githubusercontent.com/saifulemon/images-for-all-project/main/Donto/Features/feature3.svg"
    },
]