import "@fontsource/josefin-sans";
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import logo from '../../../Images/logo.jpeg';
import Carousel from 'react-bootstrap/Carousel';


import doctorfinding from '../../../Images/doctorfinding.c2532ac3.png';
import heroTeeth from '../../../Images/hero-theeth.54c2c4e9.png';
import womanbrush from '../../../Images/woman-brush.c4158ac5.png';
import './Banner.css';

import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';
import i1 from '../../../Images/1.jpeg';
import i2 from '../../../Images/2.jpeg'
import i3 from '../../../Images/3.jpeg'

const Banner = () => {
    const navigate = useNavigate()
    return (
            <section className="single-hero-slide text-white d-flex justify-content-center align-items-center">
                <Container>
                    <Row className="align-items-center">
                        <Col md={12} sm={12} lg={6}>
                            <div className="hero-slide-left justify-content-end align-items-center text-center text-lg-start">
                              
                                <h1 style={{fontFamily:'cursive'}}>Dr. Arlekar's   </h1>
                                
                                <h2>स्वस्थस्य स्वास्थ्य रक्षणं, आतुरस्य विकार प्रशमनं।</h2>
                                <p className="mb-xs-5">Ayurveda aims at keeping a healthy person healthy and managing or curing diseases of the mind, body, or both that manifest in a person.</p><p> Keeping up with this motto for more than 30 years! 
</p>
                                <div className="banner-btn m-sm-auto">
                                    <Link to="/contact"><button className="theme-btn btn-fill">Appointments</button></Link>
                                    <button className='theme-btn bth-blank' onClick={()=>navigate("/about")}>Learn More</button>
                                </div>
                            </div>
                        </Col>
             
       
                        <Col  style={{  padding:"7%"}}  md={12} sm={12} lg={6} >

                          
                           <div 
                     
                           className="hero-slide-right text-center text-lg-start mt-sm-5">
                       
                               

                           <MDBCarousel   style={{    borderRadius: 20,}}   showControls interval={5000}>
      <MDBCarouselItem itemId={1} >
        <img  src={i1} className='d-block w-100' style={{  
        
        borderRadius: 20,
          height: 400,
         }} alt='...' />
      </MDBCarouselItem>
      <MDBCarouselItem itemId={2}>
        <img src={i2}  style={{  
             borderRadius: 20,
         height: 400,
        }}  className='d-block w-100' alt='...' />
      </MDBCarouselItem>
      <MDBCarouselItem itemId={3}>
        <img  style={{  
           borderRadius: 20,
         height: 400,
        }}  src={i3} className='d-block w-100' alt='...' />
      </MDBCarouselItem>
    </MDBCarousel>


                            </div>
                        </Col>

       
                    </Row>
                </Container>
            </section>
    );
};

export default Banner;