import React from 'react';
import Appoinment from '../../Home/Appoinment/Appoinment';
import Banner from '../Bannner/Banner';
import Details from '../Details/Details';

const DoctorPage = () => {
    return (
        <>
            <Banner />
            <Details />
            <Appoinment />
        </>
    );
};

export default DoctorPage