export const FakeDoctors = [
    {
        "id": "001",
        "name": "Dr. Pravin Arlekar",
        "status": "B.A.M.S",
        "img": ''
    },
    {
        "id": "002",
        "name": "Dr. Aparna Arlekar",
        "status": "B.A.M.S",
        "img":''
    },
    {
        "id": "003",
        "name": "Dr. Ishani Arlekar",
        "status": "B.A.M.S",
        "img": ''
    }
]