import { faFacebook, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './Details.css';

const Details = () => {
    return (
        <>
            <section className="dentist-details-sec">
                <Container>
                    <Row>
                        <Col md={7} lg={8}>
                            <div className="single-dentist-details">
                                <h2>Dr. Pravin Arlekar <span>B.A.M.S (Bombay), Reg No- I-23247-A1</span></h2>
                                <p>Dr Pravin Arlekar has been in your service as General Physicians for all diseases  and Ayurveda specialists for more than 30 years , successfully treating thousands of patients in the most efficient and authentic manner. Our approach is to analyze each patient’s history, diet, lifestyle, and prakruti to get to the root cause of his ailment , formulate a personalised treatment plan  and eradicate the disease completely. 
We strive for excellence with an integrated approach of our ancient wisdom of Ayurveda as well as modern medicine and diagnostic techniques . Our results speak for themselves , here is why you should choose us.
</p>               </div>
                            <div className="dentist-award">
                                <h3>Medical Associations and Certifications</h3>
                                <ul className="p-0">
                                <li>Royal College of Physicians
                                  Multidisciplinary  Approaches in Osteoarthritis Management </li>
                                                                    <li>Certificate course in Practical Dibetology
</li>
                                    <li>Maharashtra Council of Indian Medicine’ certificate  
                                   Trained AYUSH Doctor for COVID-19 Preparedness, Response and Containment</li>
                                    <li>ASCME's Certificate in ‘Current issues in management of Respiratory Tract Infections</li>

                                </ul>
                            </div>
                        </Col>
                        <Col md={5} lg={4}>
                            <div className="dentist-profile text-center">
                                <div className="profile-img"></div>
                                <p>Name: <strong>Dr. Pravin Arlekar</strong></p>
                                <p>Specialization: <strong>Allopathy, Ayurveda</strong></p>
                                <p>Experience: <strong>30 years</strong></p>
                                <div className="doctors-social">
                                    {/* <a href=".#"><FontAwesomeIcon icon={faFacebook} /></a>
                                    <a href=".#"><FontAwesomeIcon icon={faTwitter} /></a>
                                    <a href=".#"><FontAwesomeIcon icon={faLinkedin} /></a> */}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col md={7} lg={8}>
                            <div className="single-dentist-details">
                                <h2>Dr. Aparna Arlekar <span>B.A.M.S (Bombay),  Reg No- I-23248-A1</span></h2>
                                      <p>
                                        
                                      Dr Aparna Arlekar has been in your service as General Physicians for all diseases  and Ayurveda specialists for more than 30 years , successfully treating thousands of patients in the most efficient and authentic manner. Our approach is to analyze each patient’s history, diet, lifestyle, and prakruti to get to the root cause of his ailment , formulate a personalised treatment plan  and eradicate the disease completely. 
We strive for excellence with an integrated approach of our ancient wisdom of Ayurveda as well as modern medicine and diagnostic techniques . Our results speak for themselves , here is why you should choose us.

                                        
                                        </p>                   </div>
                            <div className="dentist-award">
                                <h3>Medical Associations and Certifications</h3>
                                <ul className="p-0">
                                  
                                    <li>Certificate course  in Panchabhautik chikitsa. </li>
                                    <li>Trained AYUSH Doctor for COVID-19 Preparedness, Response and Containment</li>
                             
                                </ul>
                            </div>
                        </Col>
                        <Col md={5} lg={4}>
                            <div className="dentist-profile text-center">
                                <div className="profile-img"></div>
                                <p>Name: <strong>Dr. Aparna Arlekar</strong></p>
                                <p>Specialization: <strong>Ayurveda</strong></p>
                                <p>Experience: <strong>30 years</strong></p>
                                <div className="doctors-social">
                                    {/* <a href=".#"><FontAwesomeIcon icon={faFacebook} /></a>
                                    <a href=".#"><FontAwesomeIcon icon={faTwitter} /></a>
                                    <a href=".#"><FontAwesomeIcon icon={faLinkedin} /></a> */}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col md={7} lg={8}>
                            <div className="single-dentist-details">
                                <h2>Dr. Ishani Arlekar <span>B.A.M.S (Bombay), Reg No- I-98571-A</span></h2>
                               <p>
                               Dr Ishani has been in your service as General Physicians for all diseases  and Ayurveda specialists for more than 1 year, successfully treating thousands of patients in the most efficient and authentic manner. Our approach is to analyze each patient’s history, diet, lifestyle, and prakruti to get to the root cause of his ailment , formulate a personalised treatment plan  and eradicate the disease completely. 
We strive for excellence with an integrated approach of our ancient wisdom of Ayurveda as well as modern medicine and diagnostic techniques . Our results speak for themselves , here is why you should choose us.
</p>                        </div>
                            <div className="dentist-award">
                                <h3>Medical Associations and Certifications</h3>
                                <ul className="p-0">
                                    <li>Certificate course in Ayurveda Cardiology course                                                                                                                                       -                                                                                                  Levels- 1 , 2 and 3 
                                              
</li>
                                    <li>Certificate on ‘COVID 19- Awareness and management’ by Medvarsity</li>
                                  
                                </ul>
                            </div>
                        </Col>
                        <Col md={5} lg={4}>
                            <div className="dentist-profile text-center">
                                <div className="profile-img"></div>
                                <p>Name: <strong>Dr. Ishani Arlekar</strong></p>
                                <p>Specialization: <strong>Ayurveda</strong></p>
                                <p>Experience: <strong>1+ years</strong></p>
                                <div className="doctors-social">
                                    {/* <a href=".#"><FontAwesomeIcon icon={faFacebook} /></a>
                                    <a href=".#"><FontAwesomeIcon icon={faTwitter} /></a>
                                    <a href=".#"><FontAwesomeIcon icon={faLinkedin} /></a> */}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="pb-5">
            <Container>
                {/* <Row className="align-items-center">
                    <Col lg={6}>
                        <div className="achivement-img-bg"></div>
                    </Col>
                    <Col lg={6}>
                        <div className="expertDentist-txt mt-5 mt-lg-0">
                            <h2>Experienced Dentist</h2>
                            <p>Smiling comes naturally to Dr. Harrie, author of ‘Donto’. He has embraced Cosmetic Dentistry and has redesigned the smiles for thev thousands of patients.</p>
                            <p>Dr. Harrie believes in providing her patients with more than just world class medical care. He also helps patients recognize the vital connection between medical health and whole body health. A graduate of the University of California’s School of Dentistry, Dr. Harrie is a leader in the movement to bring environmental sanity and well-being into the medical world for future.</p>
                        </div>
                    </Col>
                </Row> */}
            </Container>
        </section>
        </>
    );
};

export default Details;